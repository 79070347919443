export const footballItems = [
  {
    title: 'Home',
    icon: 'mdi-home',
    group: 'Dashboard',
    to: '/app/football/home',
  },
  {
    subheader: 'Apps',
    icon: 'mdi-apps-box',
    divider: true,
  },
  // {
  //   title: 'Selection',
  //   icon: 'mdi-gesture-tap',
  //   group: 'football/selection',
  //   children: [
  //     {
  //       title: 'Current',
  //       icon: 'mdi-clock-outline',
  //       to: 'current',
  //     },
  //     {
  //       title: 'History',
  //       icon: 'mdi-history',
  //       to: 'history',
  //     }
  //   ],
  // },
  // {
  //   title: 'Leagues',
  //   icon: 'mdi-chart-line',
  //   group: 'football/leagues',
  //   children: [
  //     {
  //       subheader: 'Public Leagues',
  //       divider: true,
  //       children: [
  //         {
  //           title: 'Overall',
  //           icon: 'mdi-earth',
  //           to: '6',
  //         },
  //         // {
  //         //   title: 'County',
  //         //   icon: 'mdi-flag-outline',
  //         //   to: 'overall',
  //         // },
  //         {
  //           title: 'Club',
  //           icon: 'mdi-tshirt-crew-outline',
  //           to: 'club',
  //         },
  //       ],
  //     },
  //     {
  //       subheader: 'Private Leagues',
  //       divider: true,
  //       options: true,
  //       children: [
          
  //       ],
  //     }
  //   ],
  // },
  {
    title: 'Matches',
    icon: 'mdi-calendar-month-outline',
    group: 'football/matches',
    children: [
      {
        title: 'Fixtures',
        icon: 'mdi-circle-medium',
        to: 'fixtures',
      },
      {
        title: 'Results',
        icon: 'mdi-circle-medium',
        to: 'results',
      }
    ],
  },
  {
    title: 'Standings',
    icon: 'mdi-tournament',
    group: 'football/standings',
    children: [
      {
        subheader: 'Teams',
        divider: true,
        children: [
          {
            title: 'Munster',
            icon: 'mdi-circle-medium',
            to: 'munster',
          },
          {
            title: 'Leinster',
            icon: 'mdi-circle-medium',
            to: 'leinster',
          }
        ],
      }
    ],
  },
  // {
  //   title: 'Insights',
  //   icon: 'mdi-head-snowflake-outline',
  //   group: 'insights',
  //   children: [
  //     // {
  //     //   title: 'You',
  //     //   icon: 'mdi-circle-medium',
  //     //   to: 'individual',
  //     // },
  // //     {
  // //       title: 'Your Club/League',
  // //       icon: 'mdi-circle-medium',
  // //       to: 'club',
  // //     },
  // //     {
  // //       title: 'Your County',
  // //       icon: 'mdi-circle-medium',
  // //       to: 'county',
  // //     },
  //     {
  //       title: 'Gameweek',
  //       icon: 'mdi-circle-medium',
  //       to: 'team',
  //     }
  //   ],
  // },
  {
    title: 'Statistics',
    icon: 'mdi-chart-box-outline',
    group: 'football/statistics',
    children: [
      {
        subheader: 'Team',
        divider: true,
        children: [
          {
            title: 'Honours',
            icon: 'mdi-trophy-variant-outline',
            to: 'honours',
          },
          {
            title: 'Team Records',
            icon: 'mdi-account-group-outline',
            to: 'team',
          },
          {
            title: 'Ranking',
            icon: 'mdi-order-numeric-ascending',
            to: 'ranking',
          }
        ],
      }
    ],
  },
  {
    subheader: 'Help',
    icon: 'mdi-apps-box',
    group: 'help',
    divider: true,
  },
  {
    title: 'FAQ',
    icon: 'mdi-frequently-asked-questions',
    group: 'pages',
    to: '/help/faq'
  },
  {
    title: 'About',
    icon: 'mdi-information-variant',
    group: 'pages',
    to: '/help/about'
  },
  {
    title: 'Legal',
    icon: 'mdi-scale-balance',
    group: 'pages',
    to: '/help/legal'
  },
]
